import index from '../components/pages/index.vue';
import test from '../components/pages/test.vue';
import test2 from '../components/pages/test2.vue';


export default {
	mode:'history',
	base:process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'index',
			component: index
		},
		{
			path: '/test',
			name: 'test',
			component: test,
			meta: {
				title: 'test'
			}
		},
		{
			path: '/test2',
			name: 'test2',
			component: test2,
			meta: {
				title: 'test2'
			}
		},
	],
	scrollBehavior:function(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		}else{
			return { x: 0, y: 0 };
		}
	},
};