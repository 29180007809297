export default {
	namespaced: true,
	state:{
		tables:[],
		persons:[],
		operator:[],
		sales:[],
	},
	mutations:{
		setLists:(state,args) => {
			state[args.param] = args.list;
		},
	},
	actions:{
		setLists(context,args) {
			context.commit('setLists',args);
		},
	},
	getters:{
		allData:state => {
			return Object.assign({},state);
		},
		isLoaded:state => {
			const res = Object.keys(state).filter(key => {
				return state[key].length === 0;
			});
			return (res.length === 0);
		},
	}
}


