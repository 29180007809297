import ajax from '../api/ajax';
import moment from 'moment';
import 'moment-timezone';


export default {
	data:function(){
		return {
			loading:0,
			defineObj:this.$store.getters['defineObj/allData'],
			baseLists:{},
			baseListsLoaded:false,
			ajax:null,
		}
	},
	created:function(){
		moment.tz.setDefault('Asia/Tokyo');
		this.initAjax();
		this.createBaseLists();
	},
	components:{
	},
	methods:{
		getRoutesPageObj:function(pageName){
			const routes = this.$router.options.routes;
			return routes.find(obj => {
				return (this.isset(obj.name) && obj.name === pageName);
			});
		},
		getRoutesPageTitle:function(pageName){
			const target = this.getRoutesPageObj(pageName);
			return (this.isset(target.meta) && this.isset(target.meta.title)) ? target.meta.title : '';
		},
		getRoutesPagePath:function(pageName){
			const target = this.getRoutesPageObj(pageName);
			return (this.isset(target.path)) ? target.path : '';
		},
		getSelectedDbTable:function(){
			return this.$store.getters['baseData/selectedDbTable'];
		},
		getBaseData:function(key){
			return this.$store.getters['baseData/allData'][key];
		},
//----------------------------------------
		getLocal:function(localStorageObjKey){
			const v = window.localStorage.getItem(localStorageObjKey);
			return (this.isset(v)) ? JSON.parse(v) : null;
		},
		saveLocal:function(localStorageObjKey,data){
			window.localStorage.setItem(localStorageObjKey,JSON.stringify(data));
		},
//----------------------------------------
		addLoading:function(){
			this.loading++;
		},
		remLoading:function(){
			this.loading--;
		},
//----------------------------------------
		createBaseLists: async function(){
			if(this.baseListsLoaded){
				this.baseListsLoadAfter();
				return;
			}

			await this.createRecordedLists();
			this.baseLists = Object.assign({},this.$store.getters['recordedObj/allData']);
			this.baseLists = Object.assign(this.baseLists,this.$store.getters['defineObj/defineBaseObj']);
			this.$forceUpdate();
			this.baseListsLoaded = true;
		},
		createRecordedLists: async function(){
			if(this.$store.getters['recordedObj/isLoaded']) return;

			const recordedObj = {};

			const dbTablesFunc = async () => {
				recordedObj.tables = await this.getDbTables();
			};
			const personsFunc = async () => {
				recordedObj.persons = await this.getPersonsTable();
				if(!recordedObj.persons){
					recordedObj.persons = [];
					return;
				}
				recordedObj.persons.forEach(v => {
					if(!this.isset(recordedObj[v.type])){
						recordedObj[v.type] = [];
					}
					recordedObj[v.type].push(v.name);
				});
			};

			this.addLoading();
			await Promise.all([
				dbTablesFunc(),
				personsFunc(),
			]);
			this.remLoading();

			Object.keys(recordedObj).forEach(key => {
				this.$store.dispatch('recordedObj/setLists',{param:key,list:recordedObj[key]});
			});
		},
//----------------------------------------
		initAjax:function(){
			ajax.setRequestPath(this.$store.getters['baseData/requestPath']);
			ajax.setAjaxType('connectDB');
			ajax.setToken(token);
			this.ajax = ajax;
		},
		ajaxPost: async function(data){
			return await ajax.post(ajax.requestPath,data);
		},
		sendPost: async function(data){
			const checkBeforeUpdateRes = await this.checkBeforeUpdate(data);
			if(!checkBeforeUpdateRes) return;

			this.addLoading();
			const res = await this.ajaxPost(data);
			this.remLoading();
			const flag = (this.checkAjaxResponse(res)) ? true : false;
			return {flag:flag,data:res};
		},
		sendLoadSpinnerlessPost: async function(data){
			const checkBeforeUpdateRes = await this.checkBeforeUpdate(data);
			if(!checkBeforeUpdateRes) return;
			const res = await this.ajaxPost(data);
			const flag = (this.checkAjaxResponse(res)) ? true : false;
			return {flag:flag,data:res};
		},
		checkBeforeUpdate: async function(data){
			if(data.queryType !== 'update') return true;
			if(data.lastUpdateDatetime === 'forceUpdate') return true;

			const ckeckPostData = {
				queryType:'findOne',
				queryInfo:{
					table:data.queryInfo.table,
					data:{
						id:data.queryInfo.data.id
					}
				}
			};

			this.addLoading();
			const res = await this.ajaxPost(ckeckPostData);
			this.remLoading();
			if(this.checkAjaxResponse(res)){
				const respons = this.getResponseData(res);
				if(respons &&
					this.isset(respons.result.updateDatetime) &&
					respons.result.updateDatetime !== data.lastUpdateDatetime
				){
					alert('他のユーザーが同じデータを同時に編集している可能性があるため登録を中止しました。リロードしてから再度データ登録をしてください。');
					return false;
				}else{
					return true;
				}
			}else{
				console.log(res,'err')
				return true;
			}
		},
		responseFunc:function(res){
			console.log(res);
			//各コンポーネントでオーバーライド
		},
		errorResponseFunc:function(res){
			//各コンポーネントでオーバーライド
			const respons = this.getResponseData(res);
			console.log('err',respons.queryType,res)
			return;
		},
		checkAjaxResponse:function(res){
			if(res === undefined ||
				res.data == null ||
				res.data.data == null ||
				res.data.data.queryType == null ||
				res.data.data.result == null
			){
				return false;
			}
			return true;
		},
		getResponseData:function(res){
			if(!this.checkAjaxResponse(res)) return false;
			const responseData = {
				queryType:res.data.data.queryType,
				result:res.data.data.result,
				table:res.data.data.table,
				postKey:res.data.data.postKey,
			};
			return responseData;
		},
		getResultData:function(res){
			const respons = this.getResponseData(res);
			if(!respons) return false;
			return respons.result;
		},
//----------------------------------------
		uploadFiles: async function(fileList){
			const url = this.$store.getters['baseData/requestPath'];
			const params = new FormData();
			const infoObj = {};
			fileList.forEach(listObj => {
				params.append(listObj.key,listObj.file);
				infoObj[listObj.key] = {rename:listObj.rename,dir:listObj.dir};
			});
			params.append('infoObj',JSON.stringify(infoObj));
			params.append('ajaxType','operatFiles');
			params.append('method','upload');
			const res = await this.ajax.filePost(url,params);
			const flag = (this.checkAjaxResponse(res)) ? true : false;
			return {flag:flag,data:res};
		},
		deleteFiles: async function(fileList){
			const url = this.$store.getters['baseData/requestPath'];
			const params = new URLSearchParams();
			params.append('infoObj',JSON.stringify(fileList));
			params.set('token',token);
			params.set('ajaxType','operatFiles');
			params.set('method','delete');
			const res = await this.ajax.customPost(url,params);
			const flag = (this.checkAjaxResponse(res)) ? true : false;
			return {flag:flag,data:res};
		},
//----------------------------------------
		getPersonsTable: async function(){
			const data = {
				queryType:'findAll',
				queryInfo:{table:'persons'}
			};
			const res = await this.ajaxPost(data);
			return this.getResultData(res);
		},
		getDbTables: async function(){
			const data = {queryType:'getTableList'};
			const res = await this.ajaxPost(data);

			const list = [];
			const respons = this.getResponseData(res);
			if(!respons) return false;
			respons.result.forEach(obj => {
				list.push(obj[Object.keys(obj)[0]]);
			});
			return list;
		},
//----------------------------------------
		formatHalfWidth:function(str){
			const reStr = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g,function(s){
				return String.fromCharCode(s.charCodeAt(0) - 65248);
			});
			return reStr;
		},
		formatYear:function(str){
			return this.formatHalfWidth(str).replace(/[^0-9]/g, '');
		},
		formatSendeDate:function(date){
			if(!this.isset(date)) return null;
			const parts = date.split(' ');
			const reg = new RegExp('^[0-9]{2}:[0-9]{2}:[0-9]{2}$');
			if(this.isset(parts[1]) && reg.test(parts[1])){
				return date;
			}else{
				return parts[0] + moment().format(' HH:mm:ss');
			}
		},
		getFormatDate(date){
			if(!this.isset(date)) return null;
			const formated = moment(date).format('YYYY-MM-DD');
			return (formated === 'Invalid date') ? '' : formated;
		},
		getToday:function(){
			return moment().format('YYYY-MM-DD');
		},
		getYear:function(){
			return moment().format('YYYY');
		},
		getMonth:function(){
			return moment().format('MM');
		},
		getDay:function(){
			return moment().format('DD');
		},
		getNow:function(){
			return moment().format('YYYY-MM-DD HH:mm:ss');
		},
		getDisplayDate:function(formalDate){
			if(!this.isset(formalDate)) return '';
			const formated = moment(formalDate).format('MM/DD');
			return (formated === 'Invalid date') ? '' : formated;
		},
		getDiffDay:function(date1,date2){
			return moment(date1).diff(moment(date2),'days');
		},
		getDateInt:function(date){
			if(!this.isset(date)) return null;
			const formated = moment(date).format('YYYYMMDDHHmmss');
			return (this.isNumber(+formated)) ? +formated : false;
		},
		getTimeInt:function(date){
			if(!this.isset(date)) return null;
			const formated = moment(date).format('HHmmss');
			return (this.isNumber(+formated)) ? +formated : false;
		},
		getDayInt:function(date){
			if(!this.isset(date)) return null;
			const formated = moment(date).format('YYYYMMDD');
			return (this.isNumber(+formated)) ? +formated : false;
		},
		isNumber:function(val){
			var regex = new RegExp(/^[-+]?[0-9]+(\.[0-9]+)?$/);
			return regex.test(val);
		},
		isValidDate:function(str){
			const d = new Date(str);
			return d.toString() !== 'Invalid Date';
		},
//----------------------------------------
		trim:function(str){
			if(typeof str === 'string'){
				return str.trim();
			}else{
				return str;
			}
		},
		isset:function(data){
			if(data === '' || data === null || data === undefined){
				return false;
			}else{
				return true;
			}
		},
		divideItems:function(arr,num){
			const units = arr.reduce((table, item) => {
				const last = table[table.length - 1];
				if (last.length === num) {
					table.push([item]);
					return table;
				}
				last.push(item);
				return table;
			}, [[]]);
			return units;
		},
//----------------------------------------
		pathInfo:function(path){
			const reg = /(.*)(?:\.([^.]+$))/;
			return path.match(reg);
		},
//----------------------------------------
		isImage:function(fileType){
			const imgMimeTypeList = ['image/jpeg','image/png','image/gif'];
			return imgMimeTypeList.includes(fileType);
		},
		getImageExtByMimeType:function(mimeType){
			if(mimeType === 'image/jpeg') return 'jpg';
			if(mimeType === 'image/png') return 'png';
			if(mimeType === 'image/gif') return 'gif';
			return false;
		},
		getRecodedFilePath:function(fileObj){
			return this.$store.getters['baseData/uploadDir'] + '/' + fileObj.dir + '/' + fileObj.filename;
		},
		createUniqueFilenameByDataURI:function(dataURI,num,prefix){
			const mimeType = dataURI.match(/(:)([a-z\/]+)(;)/)[2];
			const ext = this.getImageExtByMimeType(mimeType);
			const name = prefix + this.getDateInt(this.getNow()) + num + Math.random().toString(36).slice(-8);
			return (ext) ? name + '.' + ext : name;
		},
		createUniqueFilename:function(filename,num,prefix){
			const pi = this.pathInfo(filename);
			return prefix + this.getDateInt(this.getNow()) + num + Math.random().toString(36).slice(-8) + '.' + pi[2];
		},
//----------------------------------------
		blackOrWhite:function(hexcolor){
			const r = parseInt(hexcolor.substr(1,2),16);
			const g = parseInt(hexcolor.substr(3,2),16);
			const b = parseInt(hexcolor.substr(5,2),16);
			return ((((r * 299) + (g * 587) + (b * 114)) / 1000) < 128);
		},
//----------------------------------------
		toast:function(title,content,toaster,append = false){
			this.counter++
			this.$bvToast.toast(content,{
				title:title,
				toaster:toaster,
				solid:true,
				appendToast:append,
			});
		},
		modalConfirm: async function(title,content){
			return await this.$bvModal.msgBoxConfirm(content,{
				title:title,
				size:'sm',
				buttonSize:'sm',
				okVariant:'danger',
				okTitle:'YES',
				cancelTitle:'NO',
				footerClass:'p-2',
				hideHeaderClose:false,
				centered:true
			});
		},
		toResultPage:function(res,type,pageName,params){
			const data = {
				type:type,
				res:res,
			};
			this.$store.dispatch('baseData/setResultData',JSON.stringify(data));
			if(!this.isset(pageName)){
				this.$router.push({name:'displayResult'});
			}else if(this.isset(params)){
				this.$router.push({name:pageName,params:params});
			}else{
				this.$router.push({name:pageName});
			}
		},
//----------------------------------------
		mixinTest:function(arg){
			console.log(arg)
		},
	},
//----------------------------------------
	computed:{
		selectedMode:function(){
			return this.$store.getters['baseData/selectedMode'];
		},
		isDebug:function(){
			return this.$store.getters['baseData/isDebug'];
		},
		appKey:function(){
			return  this.$store.getters['baseData/appKey'];
		},
		rootPath:function(){
			return  this.$store.getters['baseData/rootPath'];
		}
	}
}
