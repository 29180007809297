<template>
	<div id="app">
		<backup/>
		<app-header/>
		<div id="content">
			<transition mode="out-in">
				<router-view/>
			</transition>
		</div>
		<app-footer/>
	</div>
</template>


<script>
import appHeader from './components/modules/appHeader';
import appFooter from './components/modules/appFooter';

export default {
	name:'App',
	components:{
		appHeader:appHeader,
		appFooter:appFooter,
	},
};
</script>


<style>
/*-------------------------------------*/

:root {
	--main-theme-color: #26d;
}

/*-------------------------------------*/
.v-enter {transform: translate(0, 20px);opacity: 0;}
.v-enter-to {opacity: 1;}
.v-enter-active {transition: all 1s 0s ease;}
.v-leave {transform: translate(0, 0);opacity: 1;}
.v-leave-to {transform: translate(0, -20px);opacity: 0;}
.v-leave-active {transition: all .5s 0s ease;}
.flip-list-move {transition: transform 0.5s;}
.flex{display:flex;}
#app{}
#content{padding:50px 0 100px;}
.card{padding-top:20px;padding-bottom:30px;transition:all 0.2s 0s ease;}
.card h2{padding:0;}
.main.card{margin-top:50px;}
body,#app {display: flex;flex-direction: column;min-height: 100vh;}
#footer {margin-top: auto;}
.lightGrayBg{background-color:rgba(0,0,0,0.03);}
/*-------------------------------------*/
.fs09{font-size:0.9rem;}
.fs08{font-size:0.8rem;}
.fs07{font-size:0.7rem;}
.fs06{font-size:0.6rem;}
/*-------------------------------------*/
#displayPrint{padding:50px 5%;}
#displayPrint table{table-layout:auto;}
#displayPrint .editBtn{display:none;}
#displayPrint .cardTableBtn{display:none;}

@media print{
	#displayPrint .noPrint{display:none;}
	#header{display:none;}
	#footer{display:none;}
}
/*-------------------------------------*/
</style>