const branchConfig = {
	isDebug:true,
	select:'dev',
	appLastUpdate:'2020.5.10',
	appVersion:'ver 1.0.0',
	dev:{
		rootPath:'https://test.yuroy.net',
		requestPath:'https://test.yuroy.net/ajax/ajax.php',
		uploadDir:'https://test.yuroy.net/upload',
	},
	dist:{
		rootPath:'https://yuroy.net',
		requestPath:'https://yuroy.net/ajax/ajax.php',
		uploadDir:'https://yuroy.net/upload',
	}
};

//------------------------------------------------------------

export default {
	namespaced: true,
	state:{
		appName:'yuroy',
		appKey:'yuroy',
		pageTitle:'',
		token:'',
		rootPath:branchConfig[branchConfig.select].rootPath,
		requestPath:branchConfig[branchConfig.select].requestPath,
		uploadDir:branchConfig[branchConfig.select].uploadDir,
		selectedDbTable:null,
		resultData:'',
		notifyStateObjs:{
			infoPage:false,
			articles:false,
		},
		accessNotifyQue:false,
		selectedMode:branchConfig.select,
		isDebug:branchConfig.isDebug,
		appLastUpdate:branchConfig.appLastUpdate,
		appVersion:branchConfig.appVersion,
	},
	mutations:{
		setPageTitle:(state,pageTitle) => {
			state.pageTitle = pageTitle;
		},
		setToken:(state,token) => {
			state.token = token;
		},
		setSelectedDbTable:(state,selectedDbTable) => {
			state.selectedDbTable = selectedDbTable;
		},
		setResultData:(state,json) => {
			state.resultData = json;
		},
		setNotifyStateObjs:(state,data) => {
			state.notifyStateObjs[data.key] = data.state;
		},
		setAccessNotifyQue:(state,que) => {
			state.accessNotifyQue = que;
		},
	},
	actions:{
		setPageTitle(context,pageTitle) {
			context.commit('setPageTitle',pageTitle);
		},
		setToken:(context,token) => {
			context.commit('setToken',token);
		},
		setSelectedDbTable:(context,selectedDbTable) => {
			context.commit('setSelectedDbTable',selectedDbTable);
		},
		setResultData:(context,json) => {
			context.commit('setResultData',json);
		},
		setNotifyStateObjs:(context,data) => {
			context.commit('setNotifyStateObjs',data);
		},
		setAccessNotifyQue:(context,que) => {
			context.commit('setAccessNotifyQue',que);
		}
	},
	getters:{
		allData:state => {
			return Object.assign({},state);
		},
		appName:state => {
			return state.appName;
		},
		appKey:state => {
			return state.appKey;
		},
		pageTitle:state => {
			return state.pageTitle;
		},
		token:state => {
			return state.token;
		},
		rootPath:state => {
			return state.rootPath;
		},
		requestPath:state => {
			return state.requestPath;
		},
		uploadDir:state => {
			return state.uploadDir;
		},
		selectedDbTable:state => {
			return state.selectedDbTable;
		},
		resultData:state => {
			return state.resultData;
		},
		notifyStateObjs:state => {
			return state.notifyStateObjs;
		},
		accessNotifyQue:state => {
			return state.accessNotifyQue;
		},
		appLastUpdate:state => {
			return state.appLastUpdate;
		},
		appVersion:state => {
			return state.appVersion;
		},
		selectedMode:state => {
			return state.selectedMode;
		},
		isDebug:state => {
			return state.isDebug;
		}
	}
}