//------------------------------------------------------------


const test = {data:'test'};

//------------------------------------------------------------


// const articlesObj = {
// 	id:{key:'id',label:'ID',input:false,required:false,fields:true},
// 	title:{key:'title',label:'タイトル',input:'text',required:false,fields:true},
// 	author:{key:'author',label:'投稿者',input:'text',required:false,fields:true},
// 	content:{key:'content',label:'内容',input:'textarea',required:false,fields:true},
// 	files:{key:'files',label:'ファイル',input:false,required:false,fields:true},
// 	category:{key:'category',label:'カテゴリー',input:'checkbox',required:false,fields:true},
// 	tag:{key:'tag',label:'タグ',input:'checkbox',required:false,fields:true},
// 	password:{key:'password',label:'パスワード',input:'password',required:false,fields:true},
// 	date:{key:'date',label:'日付',input:'date',required:false,fields:true},
// 	registryDatetime:{key:'registryDatetime',label:'登録',input:'date',required:false,fields:false},
// 	updateDatetime:{key:'updateDatetime',label:'更新',input:'date',required:false,fields:false},
// };




//------------------------------------------------------------



export default {
	namespaced: true,
	state:{
	},
	getters:{
		allData:state => {
			return Object.assign({},state);
		},
		test:state => {
			return state.test;
		},
		defineBaseObj:state => {
			const list = [
				'test',
			];

			const obj = {};
			list.forEach(v => {
				obj[v] = state[v];
			});
			return obj;
		},

	}
}


//------------------------------------------------------------

