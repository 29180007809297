<template>
	<div id="header">
		header
		<div><router-link to="/">index</router-link></div>
		<div><router-link to="test">test</router-link></div>
		<div><router-link to="test2">test2</router-link></div>
	</div>
</template>

<script>

import mixin from '../../mixins/mixin';

export default {
	name:'appHeader',
	mixins:[mixin],
	components:{
	},
	computed:{
		getTitle:function(){
			return this.$store.getters['baseData/appName'];
		},
	}
};
</script>

<style>


</style>
