<template>
	<div>
		{{str}}
	</div>
</template>


<script>

import mixin from '../../mixins/mixin';

export default {
	name:'test',
	data:function(){
		return {
			str:'test',
			res:null,
		}
	},
	mixins:[mixin],
	components:{
	},
	mounted:function(){
		this.init();
	},
	methods:{
		init:function(){
				this.test();
		},
		test: async function(){
			console.log(this.str)
		},
	}
}


</script>



