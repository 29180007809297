import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import routes from './router/routes';
import storeModules from './store/store';
import { sync } from 'vuex-router-sync';
import loadingIcon from './components/modules/loadingIcon.vue';
import vueSmoothScroll from 'vue-smoothscroll';
import App from './App.vue';


(function(){
//--------------------------------------------------------------------
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(vueSmoothScroll);
//--------------------------------------------------------------------
const router = new VueRouter(routes);
const store = new Vuex.Store(storeModules);
sync(store, router);
//--------------------------------------------------------------------
Vue.component('loading-icon',loadingIcon);
//--------------------------------------------------------------------


const init = () => {

	new Vue({
		el: '#app',
		router:router,
		store:store,
		render: h => h(App),
		created:function(){
			document.title = this.$store.getters['baseData/appName'];
		},
		mounted:function(){
			console.log(token)
			this.setToken();
			router.afterEach((to,from) => {
				this.setAppPageTitle(to,from);
			});
		},
		methods:{
			setToken:function(){
				this.$store.dispatch('baseData/setToken',token);
			},
			setAppPageTitle:function(to,from){
				if(to.meta && to.meta.title){
					document.title = to.meta.title + ' | ' + this.$store.getters['baseData/appName'];
				}else{
					document.title = this.$store.getters['baseData/appName'];
				}
			}

		}
	});

};

window.addEventListener('DOMContentLoaded',() => {
	init();
});


//--------------------------------------------------------------------

})();
