import baseData from './modules/baseData';
import defineObj from './modules/defineObj';
import recordedObj from './modules/recordedObj';
// import testData from './modules/testData';

export default {
	modules: {
		baseData:baseData,
		defineObj:defineObj,
		recordedObj:recordedObj,
		// testData:testData,
	}
};