<template>
	<div id="footer">
		footer
	</div>
</template>

<script>
export default {
	name:'appFooter',
	computed:{
		getAppName:function(){
			return this.$store.getters['baseData/appName'];
		},
		getLastUpdate:function(){
			return this.$store.getters['baseData/appVersion'] + ' / ' + this.$store.getters['baseData/appLastUpdate'];
		},
	}
};
</script>

<style>

#footer{font-size:0.8rem;}

</style>